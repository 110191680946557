import React, { useState, useEffect } from "react";
import { Row, Button, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { contactReasonPageLoadedEvent, contactReasonPageCompletedEvent } from '../component/FacebookPixelEvents';
import FullScreenSpinner from '../component/FullScreenSpinner';
import axios from 'axios';
import Pages from './pages';

function ContactReason({ activePage, setActivePage, formData, setFormData }) {
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const commomTranslate = (key) => {
        return t(`Common.${key}`);
    };

    const translate = (key) => {
        return t(`ContactReason.${key}`);
    };

    useEffect(() => {
        if (activePage === Pages.ContactReason) {
            contactReasonPageLoadedEvent();
        }
    }, [activePage]);

    const selected = (e) => {
        if (!formData.ContactReason) {
            formData.ContactReason = [e];
        }
        else {
            const newReasons = formData.ContactReason;
            const idx = newReasons.indexOf(e);
            if (idx === -1) {
                newReasons.push(e);
            }
            else {
                newReasons.splice(idx, 1);
            }

            formData.ContactReason = newReasons;
        }

        setFormData({ ...formData });
    };

    const isSelected = (e) => {
        if (!formData.ContactReason) return false;

        return formData.ContactReason.includes(e);
    };

    const validate = () => {
        const errors = {};
        if (!formData.ContactReason || formData.ContactReason.length === 0) errors.ContactReason = commomTranslate('SelectOne');

        if (Object.values(errors).find((e) => e)) {
            setError(errors);
            return false;
        }

        return true;
    };

    const next = async () => {
        if (!validate()) {
            return;
        }

        await updateToXano(formData.XanoId, '8');

        setActivePage(Pages.ContactInfo);

        contactReasonPageCompletedEvent();
    };

    const updateToXano = async (id, stage) => {
        setLoading(true);

        const url = 'https://xyrm-sqqj-hx6t.n7c.xano.io/api:jajN3pdi/update_stage_tax_quotes';
        const payload = {
            id,
            Stage: stage,
        };

        try {
            await axios.patch(url, payload);
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
            console.log('Error: ', e);
        }
    };

    return (
        <div className="initial-step-container" style={{ padding: '0 5%' }}>
            <Row className="text-center mt-4">
                <Col>
                    <h2 className="heading_emp" style={{ fontSize: 'clamp(1.5rem, 4vw, 2.5rem)', fontWeight: 'bold', color: '#0f4761', marginBottom: '1.5rem' }}>
                        {translate('WhatPromptedYouToReachOutToUs')}
                    </h2>
                    <p>{translate('SelectAllThatApply')}</p>
                </Col>
            </Row>

            {/* Buttons for Select All */}
            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() => selected('Received An Irs Notice')}
                        style={{
                            width: '100%',
                            maxWidth: '240px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            padding: '12px 0',
                            backgroundColor: isSelected('Received An Irs Notice') ? 'orange' : '#e0e0e0',
                            color: isSelected('Received An Irs Notice') ? 'black' : 'inherit',
                            border: 'none',
                            marginBottom: '12px',
                            transition: 'background-color 0.3s ease',
                            cursor: 'pointer',
                        }}
                    >
                        {translate('ReceivedAnIrsNotice')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() => selected('Wages Garnished Or Bank Account Levied')}
                        style={{
                            width: '100%',
                            maxWidth: '240px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            padding: '12px 0',
                            backgroundColor: isSelected('Wages Garnished Or Bank Account Levied') ? 'orange' : '#e0e0e0',
                            color: isSelected('Wages Garnished Or Bank Account Levied') ? 'black' : 'inherit',
                            border: 'none',
                            marginBottom: '12px',
                            transition: 'background-color 0.3s ease',
                            cursor: 'pointer',
                        }}
                    >
                        {translate('WagesGarnishedOrBankAccountLevied')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() => selected('Lien Placed On Property')}
                        style={{
                            width: '100%',
                            maxWidth: '240px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            padding: '12px 0',
                            backgroundColor: isSelected('Lien Placed On Property') ? 'orange' : '#e0e0e0',
                            color: isSelected('Lien Placed On Property') ? 'black' : 'inherit',
                            border: 'none',
                            marginBottom: '12px',
                            transition: 'background-color 0.3s ease',
                            cursor: 'pointer',
                        }}
                    >
                        {translate('LienPlacedOnProperty')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() => selected('Marital Status Changed')}
                        style={{
                            width: '100%',
                            maxWidth: '240px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            padding: '12px 0',
                            backgroundColor: isSelected('Marital Status Changed') ? 'orange' : '#e0e0e0',
                            color: isSelected('Marital Status Changed') ? 'black' : 'inherit',
                            border: 'none',
                            marginBottom: '12px',
                            transition: 'background-color 0.3s ease',
                            cursor: 'pointer',
                        }}
                    >
                        {translate('MaritalStatusChanged')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() => selected('Other')}
                        style={{
                            width: '100%',
                            maxWidth: '240px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            padding: '12px 0',
                            backgroundColor: isSelected('Other') ? 'orange' : '#e0e0e0',
                            color: isSelected('Other') ? 'black' : 'inherit',
                            border: 'none',
                            marginBottom: '12px',
                            transition: 'background-color 0.3s ease',
                            cursor: 'pointer',
                        }}
                    >
                        {translate('Other')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <p className="validation-error">{error.ContactReason}</p>
                    <Button
                        className="btn-brand"
                        type="button"
                        onClick={() => next()}
                        style={{
                            width: '100%',
                            maxWidth: '240px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            padding: '12px 0',
                            backgroundColor: '#FF9900',
                            color: '#000',
                            border: 'none',
                            transition: 'background-color 0.3s ease',
                            cursor: 'pointer',
                        }}
                        onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                        onMouseOut={(e) => e.target.style.backgroundColor = '#FF9900'}
                    >
                        {translate('Continue')}
                    </Button>
                </Col>
            </Row>

            {loading && (
                <Row>
                    <FullScreenSpinner />
                </Row>
            )}
        </div>

    );
}

export default ContactReason;
