const Pages = Object.freeze({
    TaxType: "TaxType",
    IssueReason: "IssueReason",
    UnfiledReturn: "UnfiledReturn",
    IrsOwe: "IrsOwe",
    ProtectAssets: "ProtectAssets",
    ContactReason: "ContactReason",
    ContactInfo: "ContactInfo",
    Thanks9: "Thanks9",
    Direct9: "Direct9",
    Checkout9: "Checkout9",
    Upsell299: "Upsell299",
    Thanks299: "Thanks299",
    Direct299: "Direct299",
    Checkout299: "Checkout299",
    PaymentSuccess: "PaymentSuccess"
});

export default Pages;
