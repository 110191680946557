import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

const LanguageSwitcher = () =>
{
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const handleSelect = (lang) =>
    {
        i18n.changeLanguage(lang);
    };

    return (
        <div style={{
            position: 'absolute',
            top: '-12px',
            right: '32px',
            zIndex: 1000
        }}>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {currentLanguage === 'es' ? 'Español' : 'English'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleSelect('en')}>English</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleSelect('es')}>Español</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default LanguageSwitcher;
