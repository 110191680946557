import React, { useEffect } from "react";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { direct9PageLoadedEvent, direct9PageCompletedEvent } from '../component/FacebookPixelEvents';
import Pages from './pages';

function Direct9({ activePage, setActivePage, formData, setFormData, directLinkTo9 })
{
    const { t } = useTranslation();
    const translate = (key) =>
    {
        return t(`Direct9.${key}`);
    };

    useEffect(() =>
    {
        if (activePage === Pages.Direct9)
        {
            direct9PageLoadedEvent();
        }
    }, [activePage]);

    const proceedToCheckout = async () =>
    {
        setActivePage(Pages.Checkout9);

        direct9PageCompletedEvent();
    };


    return (
        <div className="initial-step-container" style={{ margin: '0', padding: '0 5%' }}>
            <div className="initial-step-container" style={{ margin: '0', padding: '0 3%' }}>
                <Row className="py-3">
                    {/* Styled Container */}
                    <Col
                        xs={12}
                        className="p-2 mx-auto" // Centers the box
                        style={{
                            backgroundColor: 'lightyellow',
                            border: '3px dashed red',
                            borderRadius: '8px',
                            width: '100%', // Makes sure the box takes full width on mobile
                        }}
                    >
                        {/* Header Section */}
                        <h1
                            className="mt-3"
                            style={{
                                color: '#FF0000',
                                textAlign: 'center',
                                fontSize: 'clamp(1.4rem, 3.5vw, 2.2rem)', // Slightly smaller clamp to fit better on mobile
                                fontWeight: 'bold',
                                margin: '0 auto', // Centers and removes extra margin
                                lineHeight: '1.3', // Adds a smaller line-height to control spacing
                                padding: '0 10px' // Reduces padding around the text to avoid wrapping issues
                            }}
                        >
                            {translate('9p90')} {translate('9p91')} {translate('9p92')}
                        </h1>

                        <p
                            className="mt-4 text-center"
                            style={{
                                fontSize: 'clamp(1rem, 2.5vw, 1.2rem)', // Responsive font size
                                margin: '10px 0', // Smaller margin for tighter layout
                            }}
                        >
                            {translate('9p93')}
                        </p>

                        {/* Highlighted Subtitle */}
                        <h3
                            className="mt-4"
                            style={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: 'clamp(1.2rem, 3vw, 1.5rem)',
                                margin: '0 0 15px 0',  // Tighter margins
                            }}
                        >
                            {translate('9p94')}
                        </h3>

                        <p
                            className="mt-4"
                            style={{
                                fontSize: 'clamp(1rem, 2.5vw, 1.1rem)', // Responsive text size
                                lineHeight: '1.5', // Improve readability
                                margin: '10px 0', // Tighter margins
                            }}
                        >
                            {translate('9p95')}
                            <span style={{ fontWeight: 'bold' }}>{translate('9p96')} </span>
                            {translate('9p97')}
                            <span style={{ fontWeight: 'bold' }}>{translate('9p98')} </span>
                            {translate('9p99')}
                            <span style={{ fontWeight: 'bold' }}>{translate('9p100')} </span>
                            {translate('9p101')}
                        </p>

                        {/* Section Heading */}
                        <h3
                            className="mt-4"
                            style={{
                                fontWeight: 'bold',
                                fontSize: 'clamp(1.3rem, 3vw, 1.5rem)',
                                margin: '10px 0',  // Tighter margins
                            }}
                        >
                            {translate('9p102')}
                        </h3>

                        <div className="initial-step-container">
                            <Row className="py-4">
                                {/* Styled Container */}
                                <Col
                                    xs={12}
                                    className="p-4" v
                                    style={{
                                        backgroundColor: 'lightyellow',
                                        border: '3px dashed red',
                                        borderRadius: '8px',
                                        padding: '20px',
                                        maxWidth: '100%', // Ensuring full width usage
                                        margin: '0 auto',  // Centering the box
                                    }}
                                >
                                    {/* Header Section */}
                                    <h1
                                        className="mt-4"
                                        style={{
                                            color: '#FF0000',
                                            textAlign: 'center',
                                            fontSize: 'clamp(1.6rem, 4vw, 2.5rem)', // Adjusting clamp to be smaller on smaller devices
                                            fontWeight: 'bold',
                                            margin: '0', // Removing extra margin
                                        }}
                                    >
                                        {translate('9p90')} {translate('9p91')} {translate('9p92')}
                                    </h1>

                                    <p
                                        className="mt-4 text-center"
                                        style={{
                                            fontSize: 'clamp(1rem, 2.5vw, 1.2rem)', // Responsive font size
                                            margin: '10px 0', // Smaller margin for tighter layout
                                        }}
                                    >
                                        {translate('9p93')}
                                    </p>

                                    {/* Highlighted Subtitle */}
                                    <h3
                                        className="mt-4"
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            fontSize: 'clamp(1.2rem, 3vw, 1.5rem)',
                                            margin: '0 0 15px 0',  // Tighter margins
                                        }}
                                    >
                                        {translate('9p94')}
                                    </h3>

                                    <p
                                        className="mt-4"
                                        style={{
                                            fontSize: 'clamp(1rem, 2.5vw, 1.1rem)', // Responsive text size
                                            lineHeight: '1.5', // Improve readability
                                            margin: '10px 0', // Tighter margins
                                        }}
                                    >
                                        {translate('9p95')}
                                        <span style={{ fontWeight: 'bold' }}>{translate('9p96')} </span>
                                        {translate('9p97')}
                                        <span style={{ fontWeight: 'bold' }}>{translate('9p98')} </span>
                                        {translate('9p99')}
                                        <span style={{ fontWeight: 'bold' }}>{translate('9p100')} </span>
                                        {translate('9p101')}
                                    </p>

                                    {/* Section Heading */}
                                    <h3
                                        className="mt-4"
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: 'clamp(1.3rem, 3vw, 1.5rem)',
                                            margin: '10px 0',  // Tighter margins
                                        }}
                                    >
                                        {translate('9p102')}
                                    </h3>

                                    {/* List Group 1 */}
                                    <ListGroup as="ul" className="my-3" style={{ listStyleType: 'disc', paddingLeft: '20px', margin: '10px 0' }}>
                                        <ListGroup.Item
                                            as="li"
                                            style={{
                                                backgroundColor: '#ffffe0',
                                                border: 'none',
                                                padding: '8px 0',
                                                fontSize: 'clamp(1rem, 2.5vw, 1.1rem)', // Responsive font size
                                                listStyleType: 'disc',
                                                listStylePosition: 'inside',
                                            }}
                                        >
                                            <span style={{ fontWeight: 'bold' }}>{translate('9p103')}</span> &mdash;
                                            <span style={{ fontStyle: 'italic' }}>{translate('9p104')}</span>
                                        </ListGroup.Item>

                                        <ListGroup.Item
                                            as="li"
                                            style={{
                                                backgroundColor: '#ffffe0',
                                                border: 'none',
                                                padding: '8px 0',
                                                fontSize: 'clamp(1rem, 2.5vw, 1.1rem)', // Responsive font size
                                                listStyleType: 'disc',
                                                listStylePosition: 'inside',
                                            }}
                                        >
                                            <span style={{ fontWeight: 'bold' }}>{translate('9p105')} </span> &mdash;
                                            <span style={{ fontStyle: 'italic' }}>{translate('9p106')}</span>
                                            <span style={{ fontWeight: 'bold' }}>{translate('9p107')} </span>
                                        </ListGroup.Item>

                                        <ListGroup.Item
                                            as="li"
                                            style={{
                                                backgroundColor: '#ffffe0',
                                                border: 'none',
                                                padding: '8px 0',
                                                fontSize: 'clamp(1rem, 2.5vw, 1.1rem)', // Responsive font size
                                                listStyleType: 'disc',
                                                listStylePosition: 'inside',
                                            }}
                                        >
                                            <span style={{ fontWeight: 'bold' }}>{translate('9p108')}</span> &mdash;
                                            <span style={{ fontStyle: 'italic' }}>{translate('9p109')}</span>
                                        </ListGroup.Item>
                                    </ListGroup>

                                    <p className="mt-4" style={{ fontSize: 'clamp(1rem, 2.5vw, 1.1rem)', margin: '10px 0' }}>
                                        {translate('9p110')}
                                        <span style={{ fontWeight: 'bold' }}>{translate('9p111')}</span>
                                    </p>

                                    {/* Section Heading */}
                                    <h3
                                        className="mt-4"
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: 'clamp(1.3rem, 3vw, 1.5rem)',
                                            margin: '10px 0', // Tighter margins
                                        }}
                                    >
                                        {translate('9p112')}
                                    </h3>

                                    {/* List Group 2 */}
                                    <ListGroup as="ul" className="my-3" style={{ listStyleType: 'disc', paddingLeft: '20px', margin: '10px 0' }}>
                                        <ListGroup.Item
                                            as="li"
                                            style={{
                                                backgroundColor: '#ffffe0',
                                                border: 'none',
                                                padding: '8px 0',
                                                fontSize: 'clamp(1rem, 2.5vw, 1.1rem)', // Responsive font size
                                                listStyleType: 'disc',
                                                listStylePosition: 'inside',
                                            }}
                                        >
                                            {translate('9p121')}
                                            <span style={{ fontWeight: 'bold' }}>{translate('9p122')}</span> &mdash;
                                            <span style={{ fontStyle: 'italic' }}>{translate('9p123')}</span>
                                        </ListGroup.Item>

                                        <ListGroup.Item
                                            as="li"
                                            style={{
                                                backgroundColor: '#ffffe0',
                                                border: 'none',
                                                padding: '8px 0',
                                                fontSize: 'clamp(1rem, 2.5vw, 1.1rem)', // Responsive font size
                                                listStyleType: 'disc',
                                                listStylePosition: 'inside',
                                            }}
                                        >
                                            {translate('9p124')}
                                            <span style={{ fontWeight: 'bold' }}>{translate('9p125')} </span> &mdash;
                                            <span style={{ fontStyle: 'italic' }}>{translate('9p126')}</span>
                                            <span style={{ fontWeight: 'bold' }}>{translate('9p107')} </span>
                                        </ListGroup.Item>

                                        <ListGroup.Item
                                            as="li"
                                            style={{
                                                backgroundColor: '#ffffe0',
                                                border: 'none',
                                                padding: '8px 0',
                                                fontSize: 'clamp(1rem, 2.5vw, 1.1rem)', // Responsive font size
                                                listStyleType: 'disc',
                                                listStylePosition: 'inside',
                                            }}
                                        >
                                            {translate('9p127')}
                                            <span style={{ fontWeight: 'bold' }}>{translate('9p128')}</span>
                                        </ListGroup.Item>
                                    </ListGroup>

                                    <p className="mt-4" style={{ fontSize: 'clamp(1rem, 2.5vw, 1.1rem)', margin: '10px 0' }}>{translate('9p129')}</p>
                                    <p className="mt-4" style={{ fontSize: 'clamp(1rem, 2.5vw, 1.1rem)', margin: '10px 0' }}>
                                        {translate('9p130')} {translate('9p131')}
                                    </p>

                                    <div className='d-flex justify-content-center align-items-center'>
                                        <Button
                                            className="normal-button mt-3"
                                            type="button"
                                            onClick={proceedToCheckout}
                                            style={{
                                                width: '80%', // Button width adaptable to page width
                                                maxWidth: '480px', // Maximum width for larger screens
                                                backgroundColor: '#FF9900', // Background color
                                                color: '#000', // Black text color
                                                fontWeight: 'bold', // Bold text
                                                border: '1px solid #FF9900', // Matching border color
                                                borderRadius: '8px', // Slightly more rounded corners
                                                padding: '12px 20px', // Consistent padding for button
                                                cursor: 'pointer', // Cursor on hover
                                                textAlign: 'center', // Centered text
                                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Light shadow for effect
                                                transition: 'background-color 0.3s ease' // Smooth transition on hover
                                            }}
                                            onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'} // Darker on hover
                                            onMouseOut={(e) => e.target.style.backgroundColor = '#FF9900'} // Return to normal on mouse out
                                        >
                                            {translate('9p132')}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>


                        <p className="mt-4" style={{ fontSize: 'clamp(1rem, 2.5vw, 1.1rem)', margin: '10px 0' }}>{translate('9p129')}</p>
                        <p className="mt-4" style={{ fontSize: 'clamp(1rem, 2.5vw, 1.1rem)', margin: '10px 0' }}>
                            {translate('9p130')} {translate('9p131')}
                        </p>
                    </Col>
                </Row>
            </div>





            <Row className="mt-4">
                <Col>
                    {/* Text Content with Translations */}
                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        <span style={{ fontWeight: 'bold' }}>{translate("9p138")}</span><br />
                        <span style={{ fontWeight: 'bold' }}>{translate("9p139")} {translate("9p139-1")}</span><br />
                        <span style={{ fontWeight: 'bold' }}>{translate("9p140")}</span>
                    </p>

                    {/* Highlighted Heading */}
                    <h1 className='mt-4' style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)' }}>
                        {translate('9p90')}
                    </h1>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>{translate('9p113')} {translate('9p114')}</p>

                    {/* Images Section */}
                    <div className="text-center mt-4">
                        <img style={{ width: "80%", maxWidth: "600px", margin: "12px auto", display: "block" }} src="/BeatTheIRSVideosml.png" alt="Beat The IRS DIY Video Course" />
                        <h1 className='mt-4' style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)' }}>
                            {translate('9p24')}
                        </h1>
                    </div>

                    {/* Important Highlighted Text */}
                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        {translate("9p3")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p4")}</span>
                        {translate("9p5")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p6")}</span>
                        {translate("9p7")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p8")}</span>
                        {translate("9p9")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p10")}</span>
                    </p>

                    {/* Section Heading */}
                    <h3 className='mt-4' style={{ fontWeight: 'bold', fontSize: 'clamp(1.5rem, 3vw, 1.8rem)', textAlign: 'center' }}>
                        {translate("9p11")}
                    </h3>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        {translate("9p12")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p13")}</span>
                        {translate("9p14")}
                    </p>

                    <p className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                        {translate("9p15")}
                    </p>

                    {/* List Group Styling */}
                    <ListGroup as="ul" className="mt-3" style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0', border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', listStylePosition: 'inside'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p16')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p17')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', listStylePosition: 'inside'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p18')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p19')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', listStylePosition: 'inside'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p20')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p21')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', listStylePosition: 'inside'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p22')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p23')}</span>
                        </ListGroup.Item>
                    </ListGroup>



                    {/* Images Section */}
                    <div className="text-center mt-4">
                        <img style={{ width: "80%", maxWidth: "600px", margin: "12px auto", display: "block" }} src="/Proof1.png" alt="Proof Image" />
                        <h1 className='mt-4' style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)' }}>
                            {translate('9p24')}
                        </h1>
                    </div>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>{translate("9p25")}</p>

                    {/* Bold Statements */}
                    <p className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>{translate("9p26")}</p>
                    <p className='mt-4' style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>{translate("9p27")}</p>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        {translate("9p28")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p29")}</span>
                        {translate("9p30")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p31")}</span>
                        {translate("9p32")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p33")}</span>
                    </p>

                    {/* Proof Images */}
                    <div className="text-center mt-4">
                        <img style={{ width: "80%", maxWidth: "600px", margin: "12px auto", display: "block" }} src="/Proof6.png" alt="Proof Image 6" />
                        <img style={{ width: "80%", maxWidth: "600px", margin: "12px auto", display: "block" }} src="/Proof7.png" alt="Proof Image 7" />
                        <img style={{ width: "80%", maxWidth: "600px", margin: "12px auto", display: "block" }} src="/Proof8.png" alt="Proof Image 8" />
                    </div>

                    {/* Red Heading */}
                    <h1 className='mt-4' style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: '2.2rem' }}>
                        {translate('9p34')}
                    </h1>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        {translate("9p35")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p36")}</span>
                        {translate("9p37")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p38")}</span>
                        {translate("9p39")}
                    </p>

                    {/* List Group 2 */}
                    <ListGroup as="ul" className="mt-3" style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', listStylePosition: 'inside'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p40')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p41')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', listStylePosition: 'inside'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p42')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p43')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', listStylePosition: 'inside'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p44')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p45')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', listStylePosition: 'inside'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p46')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p47')}</span>
                        </ListGroup.Item>
                    </ListGroup>


                    {/* More Content Sections */}
                    <h1 className='mt-4' style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: '2.2rem' }}>
                        {translate('9p48')}
                    </h1>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        {translate("9p49")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p50")}</span>
                        {translate("9p51")}
                        <span style={{ fontWeight: 'bold' }}>{translate("9p52")}</span>
                        {translate("9p53")}
                    </p>

                    {/* List Group 3 */}

                    <ListGroup as="ul" className="mt-3" style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStylePosition: 'inside'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p54')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p55')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStylePosition: 'inside'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p56')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p57')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStylePosition: 'inside'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p58')}</span> &mdash;
                            <span style={{ fontStyle: 'italic' }}>{translate('9p59')}</span>
                        </ListGroup.Item>
                    </ListGroup>

                    {/* Final Image */}
                    <img style={{ width: "80%", maxWidth: "600px", margin: "20px auto", display: "block" }} src="/productimage-2.png" alt="Product Image" />

                    {/* CTA Button */}
                    <div className='d-flex justify-content-center align-items-center my-4'>
                        <Button
                            className="normal-button mt-3"
                            type="button"
                            onClick={proceedToCheckout}
                            style={{
                                width: '80%',
                                maxWidth: '480px',
                                backgroundColor: '#FF9900',
                                color: '#000',
                                fontWeight: 'bold',
                                border: '1px solid #FF9900',
                                borderRadius: '8px',
                                padding: '12px 20px',
                                cursor: 'pointer',
                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                transition: 'background-color 0.3s ease',
                            }}
                            onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                            onMouseOut={(e) => e.target.style.backgroundColor = '#FF9900'}
                        >
                            {translate('9p132')}
                        </Button>
                    </div>

                    {/* Final Text & CTA Button */}
                    <h2 className='mt-4' style={{ color: '#000000', fontWeight: 'bold', textAlign: 'center', fontSize: '2rem' }}>
                        {translate('9p84')}
                    </h2>

                    <ListGroup as="ul" className="mt-3" style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStylePosition: 'inside'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p85')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStylePosition: 'inside'
                        }}>
                            <span>{translate('9p86')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStylePosition: 'inside'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p87')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStylePosition: 'inside'
                        }}>
                            <span>{translate('9p88')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStylePosition: 'inside'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('9p89')}</span>
                        </ListGroup.Item>
                    </ListGroup>


                    {/* CTA Button */}
                    <div className='d-flex justify-content-center align-items-center my-4'>
                        <Button
                            className="normal-button mt-3"
                            type="button"
                            onClick={proceedToCheckout}
                            style={{
                                width: '80%',
                                maxWidth: '480px',
                                backgroundColor: '#FF9900',
                                color: '#000',
                                fontWeight: 'bold',
                                border: '1px solid #FF9900',
                                borderRadius: '8px',
                                padding: '12px 20px',
                                cursor: 'pointer',
                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                transition: 'background-color 0.3s ease',
                            }}
                            onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                            onMouseOut={(e) => e.target.style.backgroundColor = '#FF9900'}
                        >
                            {translate('9p132')}
                        </Button>
                    </div>

                    {/* Final Image */}
                    <img style={{ width: "80%", maxWidth: "600px", margin: "20px auto", display: "block" }} src="/money-back-guarantee-png-1.png" alt="Money Back Guarantee" />

                    <h2 className='mt-4' style={{ color: '#000000', fontWeight: 'bold', textAlign: 'center', fontSize: '2rem' }}>
                        {translate('9p443')}
                    </h2>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        {translate("9p444")}
                    </p>
                </Col>
            </Row>


            <div className='d-flex justify-content-center align-items-center'>
                <Button
                    className="normal-button mt-3"
                    type="button"
                    onClick={proceedToCheckout}
                    style={{
                        width: '80%', // Button width adaptable to page width
                        maxWidth: '480px', // Maximum width for larger screens
                        backgroundColor: '#FF9900', // Background color
                        color: '#000', // Black text color
                        fontWeight: 'bold', // Bold text
                        border: '1px solid #FF9900', // Matching border color
                        borderRadius: '8px', // Slightly more rounded corners
                        padding: '12px 20px', // Consistent padding for button
                        cursor: 'pointer', // Cursor on hover
                        textAlign: 'center', // Centered text
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Light shadow for effect
                        transition: 'background-color 0.3s ease' // Smooth transition on hover
                    }}
                    onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'} // Darker on hover
                    onMouseOut={(e) => e.target.style.backgroundColor = '#FF9900'} // Return to normal on mouse out
                >
                    {translate('9p132')}
                </Button>
            </div>

            <Row className="mt-4">
                <Col xs={12} className="p-4" style={{ backgroundColor: 'lightyellow', border: '3px dashed red', borderRadius: '8px' }}>
                    {/* What's Included in the Beat the IRS Video Course? */}
                    <h1 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('9p600')}
                    </h1>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p601")}
                    </p>
                    <h2 className="mt-4" style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: '2rem' }}>
                        {translate('9p602')}
                    </h2>

                    {/* List Group Styling */}
                    <ListGroup as="ul" className="mt-3" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p603")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p604')}</span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('9p605')}</span></p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p606")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p607')}</span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('9p608')}</span></p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p609")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p610')}</span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('9p611')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p612")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p613')}</span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('9p614')}</span>
                                <span style={{ fontWeight: 'bold' }}>{translate('9p615')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p616")}
                            </p>
                        </ListGroup.Item>
                    </ListGroup>

                    <h2 className="mt-4" style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: '2rem' }}>
                        {translate('9p617')}
                    </h2>

                    {/* List Group Styling */}
                    <ListGroup as="ul" className="mt-3" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p618")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p619')}</span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('9p620')}</span></p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p621")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p622')}</span> &mdash;
                                {translate("9p623")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p624')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p625")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p626')}</span>
                                <span style={{ fontStyle: 'italic' }}>{translate('9p627')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p628")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p629')}</span>
                                {translate("9p630")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p631')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p632")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p633')}</span>
                                {translate("9p634")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p635')}</span>
                            </p>
                        </ListGroup.Item>
                    </ListGroup>


                    <h2 className="mt-4" style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: '2rem' }}>
                        {translate('9p637')}
                    </h2>

                    {/* List Group Styling */}
                    <ListGroup as="ul" className="mt-3" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p638")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p639')}</span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('9p640')}</span></p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p641")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p642')}</span> &mdash;
                                <span style={{ fontWeight: 'bold' }}>{translate('9p643')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p644")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p645')}</span>
                                <span style={{ fontStyle: 'italic' }}>{translate('9p646')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p647")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p648')}</span>
                                <span style={{ fontWeight: 'bold' }}>{translate('9p649')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p650")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p651')}</span>
                                <span style={{ fontWeight: 'bold' }}>{translate('9p652')}</span>
                            </p>
                        </ListGroup.Item>
                    </ListGroup>


                    <h2 className="mt-4" style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: '2rem' }}>
                        {translate('9p653')}
                    </h2>

                    {/* List Group Styling */}
                    <ListGroup as="ul" className="mt-3" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p654")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p655')}</span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('9p656')}</span></p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p657")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p658')}</span> &mdash;
                                <span style={{ fontWeight: 'bold' }}>{translate('9p659')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p660")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p661')}</span>
                                <span style={{ fontStyle: 'italic' }}>{translate('9p662')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p663")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p664')}</span>
                                <span style={{ fontWeight: 'bold' }}>{translate('9p665')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p666")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p667')}</span>
                                <span style={{ fontWeight: 'bold' }}>{translate('9p668')}</span>
                            </p>
                        </ListGroup.Item>
                    </ListGroup>

                    <h2 className="mt-4" style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: '2rem' }}>
                        {translate('9p669')}
                    </h2>

                    {/* List Group Styling */}
                    <ListGroup as="ul" className="mt-3" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p670")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p671')}</span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('9p672')}</span></p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p673")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p674')}</span> &mdash;
                                <span style={{ fontWeight: 'bold' }}>{translate('9p675')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p676")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p677')}</span>
                                <span style={{ fontStyle: 'italic' }}>{translate('9p678')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p679")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p680')}</span>
                                <span style={{ fontWeight: 'bold' }}>{translate('9p681')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p682")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p683')}</span>
                                <span style={{ fontWeight: 'bold' }}>{translate('9p684')}</span>
                            </p>
                        </ListGroup.Item>
                    </ListGroup>


                    <h2 className="mt-4" style={{ color: '#FF0000', fontWeight: 'bold', textAlign: 'center', fontSize: '2rem' }}>
                        {translate('9p685')}
                    </h2>

                    {/* List Group Styling */}
                    <ListGroup as="ul" className="mt-3" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p686")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p687')}</span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('9p688')}</span></p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p689")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p690')}</span> &mdash;
                                <span style={{ fontWeight: 'bold' }}>{translate('9p691')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p692")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p693')}</span>
                                <span style={{ fontStyle: 'italic' }}>{translate('9p694')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p695")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p696')}</span>
                                <span style={{ fontWeight: 'bold' }}>{translate('9p697')}</span>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem', listStyleType: 'disc', display: 'list-item'
                        }}>
                            <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}> {translate("9p698")}
                                <span style={{ fontWeight: 'bold' }}>{translate('9p699')}</span>
                                <span style={{ fontWeight: 'bold' }}>{translate('9p700')}</span>
                            </p>
                        </ListGroup.Item>
                    </ListGroup>

                </Col>
            </Row>


            <div className='d-flex justify-content-center align-items-center'>
                <Button
                    className="normal-button mt-3"
                    type="button"
                    onClick={proceedToCheckout}
                    style={{
                        width: '80%', // Button width adaptable to page width
                        maxWidth: '480px', // Maximum width for larger screens
                        backgroundColor: '#FF9900', // Background color
                        color: '#000', // Black text color
                        fontWeight: 'bold', // Bold text
                        border: '1px solid #FF9900', // Matching border color
                        borderRadius: '8px', // Slightly more rounded corners
                        padding: '12px 20px', // Consistent padding for button
                        cursor: 'pointer', // Cursor on hover
                        textAlign: 'center', // Centered text
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Light shadow for effect
                        transition: 'background-color 0.3s ease' // Smooth transition on hover
                    }}
                    onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'} // Darker on hover
                    onMouseOut={(e) => e.target.style.backgroundColor = '#FF9900'} // Return to normal on mouse out
                >
                    {translate('9p132')}
                </Button>
            </div>

            <Row className="mt-4">
                <Col>
                    {/* Section Heading */}
                    <h1 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('9p501')}
                    </h1>

                    {/* Subheading and Paragraph Styling */}
                    <h2 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.5rem, 3.5vw, 2.2rem)', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('9p502')}
                    </h2>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('9p503')}
                    </h3>

                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p504")}
                    </p>

                    {/* Additional Section Content */}
                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p505')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p506")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p507')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p508")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p509')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p510")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p511')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p512")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', fontWeight: 'bold' }}>
                        {translate('9p513')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p514")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', fontWeight: 'bold' }}>
                        {translate('9p515')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p516")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', fontWeight: 'bold' }}>
                        {translate('9p517')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p518")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', fontWeight: 'bold' }}>
                        {translate('9p519')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p520")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', fontWeight: 'bold' }}>
                        {translate('9p521')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p522")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', fontWeight: 'bold' }}>
                        {translate('9p523')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p524")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', fontWeight: 'bold' }}>
                        {translate('9p525')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p526")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', fontWeight: 'bold' }}>
                        {translate('9p527')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p528")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', fontWeight: 'bold' }}>
                        {translate('9p529')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p530")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', fontWeight: 'bold' }}>
                        {translate('9p531')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p532")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', fontWeight: 'bold' }}>
                        {translate('9p533')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p534")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', fontWeight: 'bold' }}>
                        {translate('9p535')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p536")}
                    </p>

                    <h2 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('9p537')}
                    </h2>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p538')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p539")}
                    </p>

                </Col>
            </Row>

            <Row className="mt-4">
                <Col>
                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p540')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p541")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p542')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p543")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p544')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p545")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p546')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p547")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p548')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p549")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p550')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p551")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p552')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p553")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p554')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p555")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p556')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p557")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p558')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p559")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p560')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p561")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p562')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p563")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p564')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p565")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p566')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p567")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p568')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p569")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p570')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p571")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p572')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p573")}
                    </p>

                    <h3 className='mt-4' style={{ color: '#000', fontSize: 'clamp(1.2rem, 3vw, 1.8rem)', fontWeight: 'bold' }}>
                        {translate('9p574')}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
                        {translate("9p575")}
                    </p>
                </Col>
            </Row>
        </div>
    );
}

export default Direct9;
