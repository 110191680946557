import React from 'react';
import { Row } from 'react-bootstrap';

function SecureLogo({ showIRS = false })
{
    const imageStyles = { width: "25%", };

    return (
        <Row className="d-flex flex-row align-items-center justify-content-center">
            {showIRS && <img style={imageStyles} src="/IRS-Logo.png" />}
            <img style={imageStyles} src="/Norton-Secure.png" />
            <img style={{ width: "20%" }} src="/PayPal-Verified.png" />
            <img style={imageStyles} src="/McAfee-Secure.png" />
        </Row>
    );
}

export default SecureLogo;
