import React, { useEffect } from "react";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { direct299PageLoadedEvent, direct299PageCompletedEvent } from '../component/FacebookPixelEvents';
import Pages from './pages';

function Direct299({ activePage, setActivePage, formData, setFormData })
{
    const { t } = useTranslation();

    const translate = (key) =>
    {
        return t(`Direct299.${key}`);
    };

    useEffect(() =>
    {
        if (activePage === Pages.Direct299)
        {
            direct299PageLoadedEvent();
        }
    }, [activePage]);


    const proceedToCheckout299 = async () =>
    {
        // ignore the direct Thanks299 product page and move to checkout299
        setActivePage(Pages.Checkout299);

        direct299PageCompletedEvent();
    };

    const listStyles = { border: "unset" };

    return (
        <div className="initial-step-container">

            <div className="initial-step-container">
                <Row className="py-4">
                    {/* Styled Container */}
                    <Col xs={12} className="p-4" style={{ backgroundColor: 'lightyellow', border: '3px dashed red', borderRadius: '8px' }}>

                        {/* Highlighted Subtitle */}
                        <h3 className='mt-4' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 'clamp(1.2rem, 4vw, 1.5rem)' }}>
                            {translate("u6")}
                        </h3>

                        <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                            {translate('u7')}
                        </p>

                        {/* List Group 1 */}
                        <ListGroup as="ul" className="my-3">
                            <ListGroup.Item as="li" style={{
                                backgroundColor: '#ffffe0',
                                border: 'none', padding: '8px 0', fontSize: '1.1rem'
                            }}>
                                <span style={{ fontWeight: 'bold' }}>{translate('u8')}</span>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" style={{
                                backgroundColor: '#ffffe0',
                                border: 'none', padding: '8px 0', fontSize: '1.1rem'
                            }}>
                                <span style={{ fontWeight: 'bold' }}>{translate('u9')} </span>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" style={{
                                backgroundColor: '#ffffe0',
                                border: 'none', padding: '8px 0', fontSize: '1.1rem'
                            }}>
                                <span style={{ fontWeight: 'bold' }}>{translate('u10')}</span>
                            </ListGroup.Item>
                        </ListGroup>

                        <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                            {translate('u11')}
                        </p>

                        {/* Section Heading */}
                        <h3 className='mt-4' style={{ fontWeight: 'bold', fontSize: 'clamp(1.2rem, 4vw, 1.5rem)' }}>
                            {translate("u12")}
                        </h3>

                        <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                            {translate('u13')}
                        </p>

                        <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                            {translate('u14')} <span style={{ fontWeight: 'bold' }}> {translate('u15')} </span> {translate('u16')}
                        </p>

                        <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                            <span style={{ fontWeight: 'bold' }}> {translate('u17')} </span>  {translate('u18')} <span style={{ fontWeight: 'bold' }}> {translate('u19')} </span> {translate('u20')}
                        </p>

                        {/* Section Heading */}
                        <h3 className='mt-4' style={{ fontWeight: 'bold', fontSize: 'clamp(1.2rem, 4vw, 1.5rem)' }}>
                            {translate("u21")}
                        </h3>

                        {/* List Group 1 */}
                        <ListGroup as="ul" className="my-3">
                            <ListGroup.Item as="li" style={{
                                backgroundColor: '#ffffe0',
                                border: 'none', padding: '8px 0', fontSize: '1.1rem'
                            }}>
                                <span style={{ fontWeight: 'bold' }}>{translate('u22')}</span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('u23')}</span>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" style={{
                                backgroundColor: '#ffffe0',
                                border: 'none', padding: '8px 0', fontSize: '1.1rem'
                            }}>
                                <span style={{ fontWeight: 'bold' }}>{translate('u24')} </span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('u25')}</span>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" style={{
                                backgroundColor: '#ffffe0',
                                border: 'none', padding: '8px 0', fontSize: '1.1rem'
                            }}>
                                <span style={{ fontWeight: 'bold' }}>{translate('u26')}</span> &mdash;
                                <span style={{ fontStyle: 'italic' }}>{translate('u27')}</span>
                            </ListGroup.Item>
                        </ListGroup>

                        <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                            {translate('u28')}
                            <span style={{ fontWeight: 'bold' }}>{translate('u29')}</span>
                        </p>

                        {/* Section Heading */}
                        <h3 className='mt-4' style={{ fontWeight: 'bold', fontSize: 'clamp(1.2rem, 4vw, 1.5rem)' }}>
                            {translate("u30")}
                        </h3>

                        <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                            {translate('u31')}
                            <span style={{ fontWeight: 'bold' }}>{translate('u32')}</span>
                            {translate('u33')}
                        </p>

                        {/* List Group 2 */}
                        <ListGroup as="ul" className="my-3">
                            <ListGroup.Item as="li" style={{
                                backgroundColor: '#ffffe0',
                                border: 'none', padding: '8px 0', fontSize: '1.1rem'
                            }}>
                                <span style={{ fontWeight: 'bold' }}>{translate('u34')}</span>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" style={{
                                backgroundColor: '#ffffe0',
                                border: 'none', padding: '8px 0', fontSize: '1.1rem'
                            }}>
                                <span style={{ fontWeight: 'bold' }}>{translate('u35')} </span>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" style={{
                                backgroundColor: '#ffffe0',
                                border: 'none', padding: '8px 0', fontSize: '1.1rem'
                            }}>
                                <span style={{ fontWeight: 'bold' }}>{translate('u36')}</span>
                            </ListGroup.Item>
                        </ListGroup>

                        <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                            {translate('u37')}
                            <span style={{ fontWeight: 'bold' }}>{translate('u38')}</span>
                        </p>

                        {/* Section Heading */}
                        <h3 className='mt-4' style={{ fontWeight: 'bold', fontSize: 'clamp(1.2rem, 4vw, 1.5rem)' }}>
                            {translate("u39")}
                        </h3>

                        {/* Checkout Button */}
                        <div className='d-flex justify-content-center align-items-center mt-4'>
                            <Button
                                className="normal-button"
                                type="button"
                                onClick={proceedToCheckout299}
                                style={{
                                    width: '100%',
                                    maxWidth: '400px',
                                    borderRadius: '4px',
                                    backgroundColor: 'orange',
                                    color: 'black',
                                    fontWeight: 'bold',
                                    padding: '12px 20px',
                                    textAlign: 'center'
                                }}
                            >
                                {translate('u40')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>


            <Row className="mt-4">
                <Col xs={12}>

                    {/* Section Heading */}
                    <h3 className='mt-4' style={{ fontWeight: 'bold', fontSize: 'clamp(1.2rem, 4vw, 1.5rem)' }}>
                        {translate("u41")}
                    </h3>
                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        {translate('u42')}
                        <span style={{ fontWeight: 'bold' }}>{translate('u43')}</span>
                        {translate('u44')}
                    </p>

                    {/* List Group 2 */}
                    <ListGroup as="ul" className="my-3">
                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('u45')}</span>
                            <span style={{ fontStyle: 'italic' }}>{translate('u46')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('u47')} </span>
                            <span style={{ fontStyle: 'italic' }}>{translate('u48')}</span>
                        </ListGroup.Item>
                    </ListGroup>

                    <p className='mt-4' style={{ fontSize: '1.1rem' }}>
                        {translate('u49')}
                        <span style={{ fontWeight: 'bold' }}>{translate('u50')}</span>
                        {translate('u51')}
                    </p>

                    {/* List Group 2 */}
                    <ListGroup as="ul" className="my-3">
                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('u52')}</span>
                            <span style={{ fontStyle: 'italic' }}>{translate('u53')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('u54')} </span>
                            <span style={{ fontStyle: 'italic' }}>{translate('u55')}</span>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={{
                            backgroundColor: '#ffffe0',
                            border: 'none', padding: '8px 0', fontSize: '1.1rem'
                        }}>
                            <span style={{ fontWeight: 'bold' }}>{translate('u56')} </span>
                            <span style={{ fontStyle: 'italic' }}>{translate('u57')}</span>
                        </ListGroup.Item>
                    </ListGroup>

                    <p className='mt-4'>
                        <span style={{ fontWeight: 'bold' }}>{translate("299p1")} </span><br />
                        <span style={{ fontWeight: 'bold' }}>{translate("299p2")} {translate("299p2-1")} </span><br />
                        <span style={{ fontWeight: 'bold' }}>{translate("299p3")}</span>
                    </p>

                    <h2 className='mt-4' style={{ color: '#FF0000', fontSize: 'clamp(1.5rem, 5vw, 2rem)', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('299p5')}
                    </h2>

                    <p className='mt-4'>{translate("299p6")}{translate("299p6-1-1")}</p>

                    <p className='mt-4' style={{ fontWeight: 'bold' }}>{translate("299p6-1")}</p>

                    <p className='mt-4'>{translate("299p7")}</p>

                    <img style={{ width: "100%", maxWidth: "600px", margin: "12px auto", display: "block" }} src="/3steps-final.png" alt="3 Steps" />

                    <p className='mt-4'>{translate("299p7-1")}</p>

                    <h2 className='mt-4' style={{ color: '#FF0000', fontSize: 'clamp(1.5rem, 5vw, 2rem)', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('299p8')}
                    </h2>

                    <p className='mt-4'>{translate("299p9")}</p>
                    <p className='mt-4'><span style={{ fontWeight: 'bold' }}>{translate("299p10")}</span></p>
                    <p className='mt-4'>{translate("299p11")}</p>
                    <p className='mt-4'><span style={{ fontWeight: 'bold' }}>{translate("299p12")}</span></p>
                    <p className='mt-4'>{translate("299p13")}</p>

                    <img style={{ width: "100%", maxWidth: "600px", margin: "12px auto", display: "block" }} src="/OldWayNewWay1-1.png" alt="Old Way vs New Way" />

                    <div style={{ border: '2px dashed #FF0000', padding: '20px', borderRadius: '8px', marginTop: '20px' }}>
                        <h3 className='mt-4' style={{ color: '#FF0000', fontSize: '1.8rem', fontWeight: 'bold', textAlign: 'center' }}>
                            {translate("299p14")}
                        </h3>

                        {/* ListGroup Items */}
                        <ListGroup as="ul" className="mt-4">
                            <ListGroup.Item style={listStyles}>
                                <span style={{ fontWeight: 'bold' }}>{translate('299p15')}</span> &mdash; <span style={{ fontStyle: 'italic' }}>{translate('299p16')}</span>
                            </ListGroup.Item>
                            <ListGroup.Item style={listStyles}>
                                <span style={{ fontWeight: 'bold' }}>{translate('299p17')} {translate('299p17-1')}</span> &mdash; <span style={{ fontStyle: 'italic' }}>{translate('299p18')}</span>
                            </ListGroup.Item>
                            <ListGroup.Item style={listStyles}>
                                <span style={{ fontWeight: 'bold' }}>{translate('299p19')}</span> &mdash; <span style={{ fontStyle: 'italic' }}>{translate('299p20')}</span>
                            </ListGroup.Item>
                            <ListGroup.Item style={listStyles}>
                                <span style={{ fontWeight: 'bold' }}>{translate('299p21')}</span> &mdash; <span style={{ fontStyle: 'italic' }}>{translate('299p22')}</span>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>

                    <h2 className='mt-4' style={{ color: '#FF0000', fontSize: 'clamp(1.5rem, 5vw, 2rem)', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('299p23')}
                    </h2>

                    {/* Checkout Button */}
                    <div className='d-flex justify-content-center align-items-center mt-4'>
                        <Button
                            className="normal-button"
                            type="button"
                            onClick={proceedToCheckout299}
                            style={{
                                width: '100%',
                                maxWidth: '400px',
                                borderRadius: '4px',
                                backgroundColor: 'orange',
                                color: 'black',
                                fontWeight: 'bold',
                                padding: '12px 20px',
                                textAlign: 'center'
                            }}
                        >
                            {translate('299p39')}
                        </Button>
                    </div>

                    <h2 className='mt-4' style={{ color: '#FF0000', fontSize: 'clamp(1.5rem, 5vw, 2rem)', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('299p24')}
                    </h2>

                    {/* Additional Proof Images */}
                    {[5, 6, 7, 8].map(proof => (
                        <img key={proof} style={{ width: "100%", maxWidth: "600px", margin: "12px auto", padding: "20px 0", display: "block" }} src={`/Proof${proof}.png`} alt={`Proof ${proof}`} />
                    ))}

                    {/* Checkout Button */}
                    <div className='d-flex justify-content-center align-items-center mt-4'>
                        <Button
                            className="normal-button"
                            type="button"
                            onClick={proceedToCheckout299}
                            style={{
                                width: '100%',
                                maxWidth: '400px',
                                borderRadius: '4px',
                                backgroundColor: 'orange',
                                color: 'black',
                                fontWeight: 'bold',
                                padding: '12px 20px',
                                textAlign: 'center'
                            }}
                        >
                            {translate('u40')}
                        </Button>
                    </div>

                    <br />
                    <br />

                    <h2 className='mt-4' style={{ color: '#0f4761', fontSize: 'clamp(1.5rem, 5vw, 2rem)', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('HowItWorks')}
                    </h2>

                    {/* Improved Numbered List: How It Works */}
                    <ListGroup as="ol" numbered className="mt-4" style={{ listStyleType: 'decimal', padding: '0 20px', fontSize: '1.1rem' }}>
                        <ListGroup.Item style={{ ...listStyles, padding: '10px 0', fontWeight: 'bold' }}>
                            <span>{translate('AccessSecurely')}</span>
                        </ListGroup.Item>
                        <ListGroup.Item style={{ ...listStyles, padding: '10px 0' }}>
                            <span>{translate('PickYourWizard')}</span>
                        </ListGroup.Item>
                        <ListGroup.Item style={{ ...listStyles, padding: '10px 0', fontWeight: 'bold' }}>
                            <span>{translate('AnswerSimpleQuestions')}</span>
                        </ListGroup.Item>
                        <ListGroup.Item style={{ ...listStyles, padding: '10px 0' }}>
                            <span>{translate('InstantDocumentPrep')}</span>
                        </ListGroup.Item>
                        <ListGroup.Item style={{ ...listStyles, padding: '10px 0', fontWeight: 'bold' }}>
                            <span>{translate('DownloadAndSign')}</span>
                        </ListGroup.Item>
                        <ListGroup.Item style={{ ...listStyles, padding: '10px 0' }}>
                            <span>{translate('MailToIrs')}</span>
                        </ListGroup.Item>
                        <ListGroup.Item style={{ ...listStyles, padding: '10px 0', fontWeight: 'bold' }}>
                            <span>{translate('EnjoyPeaceOfMind')}</span>
                        </ListGroup.Item>
                    </ListGroup>

                    <br />
                    <br />
                    {/* Checkout Button */}
                    <div className='d-flex justify-content-center align-items-center mt-4'>
                        <Button
                            className="normal-button"
                            type="button"
                            onClick={proceedToCheckout299}
                            style={{
                                width: '100%',
                                maxWidth: '400px',
                                borderRadius: '4px',
                                backgroundColor: 'orange',
                                color: 'black',
                                fontWeight: 'bold',
                                padding: '12px 20px',
                                textAlign: 'center'
                            }}
                        >
                            {translate('u40')}
                        </Button>
                    </div>

                    <img style={{ width: "80%", maxWidth: "600px", margin: "20px auto", display: "block" }} src="/money-back-guarantee-png-1.png" alt="Money Back Guarantee" />


                    <h2 className='mt-4' style={{ color: '#0f4761', fontSize: 'clamp(1.5rem, 5vw, 2rem)', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('MoneyBackGuarantee')}
                    </h2>

                    <p className='mt-4'>{translate('AutomatedTaxCreditsConfidence')}</p>
                    <p className='mt-4'>
                        <a href="https://automatedtaxcredits.com/money-back-guarantee/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                            {translate('WhyWeProvideMoneyBackGuarantee')}
                        </a>
                    </p>
                    <p className='mt-4'>{translate('IrsSupport')}</p>

                    {/* Checkout Button */}
                    <div className='d-flex justify-content-center align-items-center mt-4'>
                        <Button
                            className="normal-button"
                            type="button"
                            onClick={proceedToCheckout299}
                            style={{
                                width: '100%',
                                maxWidth: '400px',
                                borderRadius: '4px',
                                backgroundColor: 'orange',
                                color: 'black',
                                fontWeight: 'bold',
                                padding: '12px 20px',
                                textAlign: 'center'
                            }}
                        >
                            {translate('u40')}
                        </Button>
                    </div>
                    <div style={{ border: '2px dashed #FF0000', padding: '20px', borderRadius: '8px', marginTop: '20px' }}>
                        <h2 className='mt-4' style={{ color: '#0f4761', fontSize: 'clamp(1.5rem, 5vw, 2rem)', fontWeight: 'bold', textAlign: 'center' }}>
                            {formData.FirstName} {translate('u59')}
                        </h2>

                        <p className='mt-4'>{translate('u60')}</p>

                        {/* Benefits*/}
                        <ListGroup as="ul" className="mt-4" style={{ listStyleType: 'disc', paddingLeft: '20px', fontSize: '1.1rem' }}>
                            <ListGroup.Item style={{ ...listStyles, padding: '10px 0', fontWeight: 'bold', display: 'list-item', listStyleType: 'disc' }}>
                                <span>{translate('u61')}</span>
                            </ListGroup.Item>
                            <ListGroup.Item style={{ ...listStyles, padding: '10px 0', display: 'list-item', listStyleType: 'disc' }}>
                                <span>{translate('u62')}</span>
                            </ListGroup.Item>
                            <ListGroup.Item style={{ ...listStyles, padding: '10px 0', fontWeight: 'bold', display: 'list-item', listStyleType: 'disc' }}>
                                <span>{translate('u63')}</span>
                            </ListGroup.Item>
                            <ListGroup.Item style={{ ...listStyles, padding: '10px 0', display: 'list-item', listStyleType: 'disc' }}>
                                <span>{translate('u64')}</span>
                            </ListGroup.Item>
                            <ListGroup.Item style={{ ...listStyles, padding: '10px 0', fontWeight: 'bold', display: 'list-item', listStyleType: 'disc' }}>
                                <span>{translate('u65')}</span>
                            </ListGroup.Item>
                            <ListGroup.Item style={{ ...listStyles, padding: '10px 0', display: 'list-item', listStyleType: 'disc' }}>
                                <span>{translate('u66')}</span>
                            </ListGroup.Item>
                        </ListGroup>


                    </div>

                    <h2 className='mt-4' style={{ color: '#0f4761', fontSize: 'clamp(1.5rem, 5vw, 2rem)', fontWeight: 'bold', textAlign: 'center' }}>
                        {formData.FirstName} {translate('u68')}
                    </h2>
                    <p className='mt-4'>{translate('u67')}</p>
                </Col>
            </Row>
        </div>
    );
}

export default Direct299;
