import React, { useState, useEffect } from "react";
import { Row, Button, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { unfiledReturnPageLoadedEvent, unfiledReturnPageCompletedEvent } from '../component/FacebookPixelEvents';
import FullScreenSpinner from '../component/FullScreenSpinner';
import axios from 'axios';
import Pages from './pages';


function UnfiledReturn({ activePage, setActivePage, formData, setFormData }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const translate = (key) => {
        return t(`UnfiledReturn.${key}`);
    };

    useEffect(() => {
        if (activePage === Pages.UnfiledReturn) {
            unfiledReturnPageLoadedEvent();
        }
    }, [activePage]);

    const next = async (e) => {
        setFormData({
            ...formData,
            UnfiledReturn: e
        });

        await updateToXano(formData.XanoId, '5');

        unfiledReturnPageCompletedEvent();

        setActivePage(Pages.IrsOwe);
    };

    const updateToXano = async (id, stage) => {
        setLoading(true);

        const url = 'https://xyrm-sqqj-hx6t.n7c.xano.io/api:jajN3pdi/update_stage_tax_quotes';
        const payload = {
            id,
            Stage: stage,
        };

        try {
            await axios.patch(url, payload);
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
            console.log('Error: ', e);
        }
    };


    return (
        <div className="initial-step-container" style={{ padding: '0 5%' }}>
            <Row className="text-center mt-4">
                <Col>
                    <h2 className="heading_emp" style={{ fontSize: 'clamp(1.5rem, 4vw, 2.5rem)', fontWeight: 'bold', color: '#0f4761', marginBottom: '1.5rem' }}>
                        {translate('DoYouHaveUnfiledTaxReturns')}
                    </h2>
                </Col>
            </Row>

            {/* Buttons */}
            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() => next('Yes')}
                        style={{
                            width: '100%',
                            maxWidth: '240px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            padding: '12px 0',
                            backgroundColor: '#FF9900',
                            color: '#000',
                            border: 'none',
                            marginBottom: '12px',
                            transition: 'background-color 0.3s ease',
                            cursor: 'pointer',
                        }}
                        onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                        onMouseOut={(e) => e.target.style.backgroundColor = '#FF9900'}
                    >
                        {translate('Yes')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() => next('No')}
                        style={{
                            width: '100%',
                            maxWidth: '240px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            padding: '12px 0',
                            backgroundColor: '#FF9900',
                            color: '#000',
                            border: 'none',
                            marginBottom: '12px',
                            transition: 'background-color 0.3s ease',
                            cursor: 'pointer',
                        }}
                        onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                        onMouseOut={(e) => e.target.style.backgroundColor = '#FF9900'}
                    >
                        {translate('No')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() => next('NotSure')}
                        style={{
                            width: '100%',
                            maxWidth: '240px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            padding: '12px 0',
                            backgroundColor: '#FF9900',
                            color: '#000',
                            border: 'none',
                            marginBottom: '12px',
                            transition: 'background-color 0.3s ease',
                            cursor: 'pointer',
                        }}
                        onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                        onMouseOut={(e) => e.target.style.backgroundColor = '#FF9900'}
                    >
                        {translate('NotSure')}
                    </Button>
                </Col>
            </Row>

            {loading && (
                <Row>
                    <FullScreenSpinner />
                </Row>
            )}
        </div>

    );
}

export default UnfiledReturn;
