import emailMisspelled, { top100 } from "email-misspelled";
import "../asset/css/misspeller.css";
import { useState } from "react";
import { Form } from "react-bootstrap";
const checker = emailMisspelled({ domains: top100 });

const Misspeller = ({ value, onChange }) => {
  const [suggestions, setSuggestions] = useState([]);

  const onKeyUp = (event) => {
    setSuggestions(checker(event.target.value));
  };

  const onClickItem = (value) => {
    setSuggestions([]);
    onChange(value);
  };

  return (
    <>
      <Form.Control
        type="email"
        className="emailinput"
        placeholder=""
        value={value}
        onKeyUp={onKeyUp}
        onChange={(e) => onChange(e.target.value)}
      />
      {suggestions?.length ? (
        <ul className="corrections w-100">
          {suggestions.map((item, index) => (
            <li
              key={index}
              className="correctionsItem"
              onClick={() => onClickItem(item.corrected)}
            >
              {item.corrected}
            </li>
          ))}
        </ul>
      ) : (
        <></>
      )}
    </>
  );
};
export default Misspeller;
