import { Spinner } from 'react-bootstrap';

const FullScreenSpinner = () =>
{
    return (
        <div className="d-flex justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100 bg-white-80"
            style={{ zIndex: 1000, backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
            <Spinner animation="border" role="status" variant="primary">
            </Spinner>
        </div>
    );
};

export default FullScreenSpinner;
