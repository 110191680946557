import React, { useEffect, useState } from "react";
import { Row, Button, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { issueReasonPageLoadedEvent, issueReasonPageCompletedEvent } from '../component/FacebookPixelEvents';
import FullScreenSpinner from '../component/FullScreenSpinner';
import axios from 'axios';
import Pages from './pages';

function IssueReason({ activePage, setActivePage, formData, setFormData }) {
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const commomTranslate = (key) => {
        return t(`Common.${key}`);
    };

    const translate = (key) => {
        return t(`IssueReason.${key}`);
    };

    useEffect(() => {
        if (activePage === Pages.IssueReason) {
            issueReasonPageLoadedEvent();
        }

    }, [activePage]);

    const selected = (e) => {
        if (!formData.IssueReason) {
            formData.IssueReason = [e];
        }
        else {
            const newReasons = formData.IssueReason;
            const idx = newReasons.indexOf(e);
            if (idx === -1) {
                newReasons.push(e);
            }
            else {
                newReasons.splice(idx, 1);
            }

            formData.IssueReason = newReasons;
        }

        setFormData({ ...formData });
    };

    const isSelected = (e) => {
        if (!formData.IssueReason) return false;

        return formData.IssueReason.includes(e);
    };

    const validate = () => {
        const errors = {};
        if (!formData.IssueReason || formData.IssueReason.length === 0) errors.IssueReason = commomTranslate('SelectOne');

        if (Object.values(errors).find((e) => e)) {
            setError(errors);
            return false;
        }

        return true;
    };

    const next = async () => {
        if (!validate()) {
            return;
        }

        await updateToXano(formData.XanoId, '3');

        issueReasonPageCompletedEvent();

        // Skip the next Zipcode Step
        setActivePage(Pages.UnfiledReturn);
    };

    const updateToXano = async (id, stage) => {
        setLoading(true);

        const url = 'https://xyrm-sqqj-hx6t.n7c.xano.io/api:jajN3pdi/update_stage_tax_quotes';
        const payload = {
            id,
            Stage: stage,
        };

        try {
            await axios.patch(url, payload);
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
            console.log('Error: ', e);
        }
    };

    return (
        <div className="initial-step-container" style={{ padding: '0 5%' }}>
    <Row className="text-center mt-4">
        <Col>
            <h2 className="heading_emp" style={{ fontSize: 'clamp(1.5rem, 4vw, 2.5rem)', fontWeight: 'bold', color: '#0f4761', marginBottom: '1.5rem' }}>
                {translate('WhatCausedOrContributedYourTaxIssue')}
            </h2>
            <p>{translate('SelectAllThatApply')}</p>
        </Col>
    </Row>

    {/* Buttons for Select All */}
    <Row className="text-center mt-3">
        <Col>
            <Button
                className="normal-button"
                type="button"
                onClick={() => selected('Personal Or Business Financial Hardship')}
                style={{
                    width: '100%',
                    maxWidth: '240px',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    padding: '12px 0',
                    backgroundColor: isSelected('Personal Or Business Financial Hardship') ? 'orange' : '#e0e0e0',
                    color: isSelected('Personal Or Business Financial Hardship') ? 'black' : 'inherit',
                    border: 'none',
                    marginBottom: '12px',
                    transition: 'background-color 0.3s ease',
                    cursor: 'pointer',
                }}
                onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                onMouseOut={(e) => e.target.style.backgroundColor = isSelected('Personal Or Business Financial Hardship') ? 'orange' : '#e0e0e0'}
            >
                {translate('PersonalOrBusinessFinancialHardship')}
            </Button>
        </Col>
    </Row>

    <Row className="text-center mt-3">
        <Col>
            <Button
                className="normal-button"
                type="button"
                onClick={() => selected('Life disruption')}
                style={{
                    width: '100%',
                    maxWidth: '240px',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    padding: '12px 0',
                    backgroundColor: isSelected('Life disruption') ? 'orange' : '#e0e0e0',
                    color: isSelected('Life disruption') ? 'black' : 'inherit',
                    border: 'none',
                    marginBottom: '12px',
                    transition: 'background-color 0.3s ease',
                    cursor: 'pointer',
                }}
                onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                onMouseOut={(e) => e.target.style.backgroundColor = isSelected('Life disruption') ? 'orange' : '#e0e0e0'}
            >
                {translate('LifeDisruption')}
            </Button>
        </Col>
    </Row>

    <Row className="text-center mt-3">
        <Col>
            <Button
                className="normal-button"
                type="button"
                onClick={() => selected('Not Enough Withheld')}
                style={{
                    width: '100%',
                    maxWidth: '240px',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    padding: '12px 0',
                    backgroundColor: isSelected('Not Enough Withheld') ? 'orange' : '#e0e0e0',
                    color: isSelected('Not Enough Withheld') ? 'black' : 'inherit',
                    border: 'none',
                    marginBottom: '12px',
                    transition: 'background-color 0.3s ease',
                    cursor: 'pointer',
                }}
                onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                onMouseOut={(e) => e.target.style.backgroundColor = isSelected('Not Enough Withheld') ? 'orange' : '#e0e0e0'}
            >
                {translate('NotEnoughWithheld')}
            </Button>
        </Col>
    </Row>

    <Row className="text-center mt-3">
        <Col>
            <Button
                className="normal-button"
                type="button"
                onClick={() => selected('Overwhelmed Too Busy To File')}
                style={{
                    width: '100%',
                    maxWidth: '240px',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    padding: '12px 0',
                    backgroundColor: isSelected('Overwhelmed Too Busy To File') ? 'orange' : '#e0e0e0',
                    color: isSelected('Overwhelmed Too Busy To File') ? 'black' : 'inherit',
                    border: 'none',
                    marginBottom: '12px',
                    transition: 'background-color 0.3s ease',
                    cursor: 'pointer',
                }}
                onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                onMouseOut={(e) => e.target.style.backgroundColor = isSelected('Overwhelmed Too Busy To File') ? 'orange' : '#e0e0e0'}
            >
                {translate('TooBusyToFiIe')}
            </Button>
        </Col>
    </Row>

    <Row className="text-center mt-3">
        <Col>
            <Button
                className="normal-button"
                type="button"
                onClick={() => selected('Other')}
                style={{
                    width: '100%',
                    maxWidth: '240px',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    padding: '12px 0',
                    backgroundColor: isSelected('Other') ? 'orange' : '#e0e0e0',
                    color: isSelected('Other') ? 'black' : 'inherit',
                    border: 'none',
                    marginBottom: '12px',
                    transition: 'background-color 0.3s ease',
                    cursor: 'pointer',
                }}
                onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                onMouseOut={(e) => e.target.style.backgroundColor = isSelected('Other') ? 'orange' : '#e0e0e0'}
            >
                {translate('Other')}
            </Button>
        </Col>
    </Row>

    <Row className="text-center mt-3">
        <Col>
            <p className="validation-error">{error.IssueReason}</p>
            <Button
                className="btn-brand"
                type="button"
                onClick={() => next()}
                style={{
                    width: '100%',
                    maxWidth: '240px',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    padding: '12px 0',
                    backgroundColor: 'orange',
                    color: 'black',
                    border: 'none',
                    transition: 'background-color 0.3s ease',
                    cursor: 'pointer',
                }}
                onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                onMouseOut={(e) => e.target.style.backgroundColor = 'orange'}
            >
                {translate('Continue')}
            </Button>
        </Col>
    </Row>

    {loading && (
        <Row>
            <FullScreenSpinner />
        </Row>
    )}
</div>



    );
}

export default IssueReason;
