import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SecureLogo from './SecureLogo';
import SocialProof from './SocialProof';

function CheckoutFooter()
{
    const { t } = useTranslation();

    const translate = (key) =>
    {
        return t(`CheckoutFooter.${key}`);
    };

    return (
        <footer className="pb-3 pt-5 border-top border-2" style={{ borderColor: "#77777770" }}>
            <Container>
                <Row>
                    <Col md={6} className="d-flex flex-column align-items-center align-items-lg-start justify-content-center">
                        <img style={{ width: "200px" }} src="/full-logo.png" />
                        <p className="mt-4">{translate('AllRightsReserved')}</p>
                    </Col>
                    <Col md={6} className="d-flex flex-column align-items-center align-items-lg-end justify-content-center">
                        <SecureLogo />

                        <div className="d-flex flex-row justify-content-center gap-4 mt-4">
                            <a href="https://automatedtaxcredits.com/terms-and-conditions/" target="_blank">{translate('TermsAndConditions')}</a>
                            <a href="https://automatedtaxcredits.com/privacy-policy/" target="_blank">{translate('PrivacyPolicy')}</a>
                        </div>
                    </Col>
                </Row>

                <Row className='mt-4'>
                    <Col sm={0} md={0} lg={3}></Col>
                    <Col sm={12} md={12} lg={6}>
                        <p> <b>{translate('Disclosure')}: </b>{translate('AtcPreparesTaxDocumentsAsPrivateTaxPreparationCompany')}</p>
                    </Col>
                    <Col sm={0} md={0} lg={3}></Col>
                </Row>
            </Container>
        </footer>
    );
}

export default CheckoutFooter;
