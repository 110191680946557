import { useState, useEffect } from 'react';
import { Form, InputGroup, ListGroup, Container, Row, Col } from 'react-bootstrap';

const useDebounce = (value, delay) =>
{
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() =>
    {
        const handler = setTimeout(() =>
        {
            setDebouncedValue(value);
        }, delay);

        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
};

const ZipcodeAutoComplete = ({ formData, setFormData, value }) =>
{
    const [zipCodes, setZipCodes] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selected, setSelected] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const debouncedValue = useDebounce(inputValue, 500);

    useEffect(() =>
    {
        if (debouncedValue.length >= 3)
        {
            const filteredData = zipCodes.filter((item) => item.Zip.startsWith(debouncedValue)).slice(0, 5);
            setSuggestions(filteredData);
        }
        else
        {
            setSuggestions([]);
        }
    }, [debouncedValue, zipCodes]);

    useEffect(() =>
    {
        fetch('zipcode-compressed.json')
            .then((r) => r.json())
            .then((data) =>
            {
                setZipCodes(data);

                if (value)
                {
                    setInputValue(value);

                    if (value >= 3)
                    {
                        const suggestion = data.filter((item) => item.Zip.startsWith(value)).slice(0, 5).at(0);
                        if (suggestion) updateFormData(suggestion);
                    }
                }
            });
    }, []);

    const handleInputChange = (e) =>
    {
        setShowSuggestions(true);
        setInputValue(e.target.value);
        setActiveSuggestion(0);
    };

    const updateFormData = (suggestion) =>
    {
        setShowSuggestions(false);
        setFormData({ ...formData, Zipcode: suggestion.Zip, State: suggestion.State, County: suggestion.County });
        setInputValue(suggestion.Zip);
        setSelected(`${suggestion.Zip} - ${suggestion.State}, ${suggestion.County}`);
        setSuggestions([]);
    };

    const handleKeyDown = (e) =>
    {
        if (suggestions.length === 0)
        {
            return;
        }

        if (e.key === 'ArrowDown')
        {
            setActiveSuggestion((prev) =>
                prev === suggestions.length - 1 ? 0 : prev + 1
            );
        }
        else if (e.key === 'ArrowUp')
        {
            setActiveSuggestion((prev) =>
                prev === 0 ? suggestions.length - 1 : prev - 1
            );
        }
        else if (e.key === 'Enter')
        {
            e.preventDefault();
            const suggestion = suggestions[activeSuggestion];
            if (suggestion)
            {
                updateFormData(suggestion);
            }
        }

    };

    const handleFormSubmit = (e) =>
    {
        e.preventDefault();
    };

    return (
        <Container>
            <Row className="justify-content-start mt-3">
                <Col md={6}>
                    <Form onSubmit={handleFormSubmit}>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                placeholder="Enter zip code"
                                className="shadow-sm"
                            />
                        </InputGroup>
                    </Form>

                    <div>
                        <p>{selected}</p>
                    </div>

                    {showSuggestions && suggestions.length > 0 && (
                        <ListGroup className="mt-3 shadow">
                            {suggestions.map((item, index) => (
                                <ListGroup.Item key={index} style={{
                                    cursor: 'pointer',
                                    backgroundColor: index === activeSuggestion ? '#f0f0f0' : 'white',
                                }} onClick={() =>
                                {
                                    updateFormData(item);
                                }}>
                                    <strong>{item.Zip}</strong> - {item.State}, {item.County}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default ZipcodeAutoComplete;
