import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SecureLogo from './SecureLogo';

function GeneralFooter()
{
  const { t } = useTranslation();

  const translate = (key) =>
  {
    return t(`GeneralFooter.${key}`);
  };

  const listItemStyle = {
    listStyleType: 'disc',
    paddingLeft: '20px',
    marginBottom: '8px',
    fontSize: '1rem'
  };

  const paragraphStyle = {
    lineHeight: '1.6',
    fontSize: '1.1rem',
    paddingBottom: '10px'
  };

  return (
    <footer className="pb-4 pt-5 border-top border-2" style={{ borderColor: "#77777770" }}>
      <Container>
        {/* Main Content Section */}
        <div style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left', width: '90%' }}>
          <Row className="mt-4">
            <Col xs={12} className="text-center">
              <h2 className='mt-4' style={{ color: '#FF0000', fontSize: 'clamp(1.5rem, 4vw, 2rem)', fontWeight: 'bold' }}>
                {translate('Gen1')}
              </h2>
            </Col>
            <Col xs={12} className="text-start">
              <p className='mt-4 text-start' style={paragraphStyle}>
                <span style={{ fontWeight: 'bold' }}>{translate("Gen2")}</span>{translate("Gen2-1")}
              </p>
              <p className='mt-4 text-start' style={paragraphStyle}>{translate("Gen3")}</p>
              <p className='mt-4 text-start' style={paragraphStyle}>{translate("Gen4")}</p>
              <p className='mt-4 text-start' style={paragraphStyle}>{translate("Gen5")}</p>
            </Col>

            {/* List Section */}
            <Col xs={12} className="text-start">
              <ul style={{ paddingLeft: "20px" }}>
                <li style={listItemStyle}>
                  <span style={{ fontWeight: 'bold' }}>{translate('Gen6')}</span>
                  &mdash;
                  <span style={{ fontStyle: 'italic' }}>{translate('Gen6-1')}</span>
                </li>
                <li style={listItemStyle}>
                  <span style={{ fontWeight: 'bold' }}>{translate('Gen7')}</span>
                  &mdash;
                  <span style={{ fontStyle: 'italic' }}>{translate('Gen7-1')}</span>
                </li>
                <li style={listItemStyle}>
                  <span style={{ fontWeight: 'bold' }}>{translate('Gen8')}</span>
                  &mdash;
                  <span style={{ fontStyle: 'italic' }}>{translate('Gen8-1')}</span>
                </li>
              </ul>
              <p className='mt-4 text-start' style={paragraphStyle}>{translate("Gen9")}</p>
              <p className='mt-4 text-start' style={paragraphStyle}>{translate("Gen10")}</p>
              <p className='mt-4 text-start' style={paragraphStyle}>{translate("Gen11")}</p>
            </Col>

            {/* Proof Images Section */}
            <Col xs={12} className="text-center">
              {[...Array(6)].map((_, index) => (
                <div key={`proof-${index}`} className="mb-4 text-center">
                  <h2 className='mt-4 text-center' style={{ color: '#FF0000', fontSize: 'clamp(1.3rem, 4vw, 1.8rem)', fontWeight: 'bold' }}>
                    {translate(`Gen${index + 12}`)}
                  </h2>
                  <img style={{ width: "100%", maxWidth: '600px', marginTop: "12px", marginBottom: "12px", padding: "20px", display: "block", marginLeft: "auto", marginRight: "auto" }}
                    src={`/Proof${index + 1}.png`} alt={`Proof ${index + 1}`} />
                </div>
              ))}
            </Col>
          </Row>
        </div>

        {/* Footer Columns */}
        <div style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left', width: '90%' }}>
          <Row className="py-4 justify-content-center">
            <Col
              xs={12}
              md={6}
              className="d-flex flex-column align-items-center justify-content-center text-center mb-4 mb-md-0"
            >
              {/* Logo */}
              <img
                style={{ width: "150px", maxWidth: "100%", height: "auto", marginBottom: "20px" }}
                src="/full-logo.png"
                alt="Logo"
              />

              {/* Secure Logo and Links */}
              <div className="d-flex flex-column align-items-center justify-content-center text-center mt-4">
                <SecureLogo />
                <div className="d-flex flex-column flex-md-row justify-content-center gap-3 mt-3">
                  <a href="https://automatedtaxcredits.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">{translate('TermsAndConditions')}</a>
                  <a href="https://automatedtaxcredits.com/privacy-policy/" target="_blank" rel="noopener noreferrer">{translate('PrivacyPolicy')}</a>
                  <a href="https://automatedtaxcredits.com/money-back-guarantee/" target="_blank" rel="noopener noreferrer">{translate('Gen18')}</a>
                </div>
              </div>
            </Col>

            {/* Column 2: Office Locations & Contact Information */}
            <Col
              xs={12}
              md={6}
              className="d-flex flex-column align-items-center align-items-md-start justify-content-center text-center text-md-start mb-4 mb-md-0"
            >
              <div className="mb-3">
                <h5 className="mb-2">Office Locations:</h5>
                <p className="mb-2">
                  <strong>Automated Tax Credits</strong><br />
                  5342 Clark Rd Unit 1236<br />
                  Sarasota, FL 34233
                </p>
                <p>
                  <strong>Automated Tax Credits</strong><br />
                  4021 W WALNUT ST Unit 1013<br />
                  ROGERS, AR 72756-1842
                </p>
              </div>
              <div>
                <h5 className="mb-2">Contact Information:</h5>
                <p>
                  Phone/ SMS: <a href="tel:+12133291786">+1 (213) 329 1786</a><br />
                  Support: <a href="mailto:help@automatedtaxcredits.com">help@automatedtaxcredits.com</a>
                </p>
              </div>
            </Col>
          </Row>

          {/* Disclosure Section */}
          <Row className='mt-4'>
            <Col xs={12} className="text-start">
              <p className="mt-3 mb-0">{translate('AllRightsReserved')}</p>
              <p>
                <b>{translate('Disclosure')}: </b>{translate('AtcPreparesTaxDocumentsAsPrivateTaxPreparationCompany')}
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
}

export default GeneralFooter;
