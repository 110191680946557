import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SecureLogo from './SecureLogo';
import SocialProof from './SocialProof';

function ThanksFooter()
{
    const { t } = useTranslation();

    const translate = (key) =>
    {
        return t(`ThanksFooter.${key}`);
    };

    return (
        <footer className="pb-3 pt-5 border-top border-2" style={{ borderColor: "#77777770" }}>
            <Container>
            <Row className="py-4">
    {/* Column 1: Logo and Rights Reserved */}
    <Col xs={12} md={4} className="d-flex flex-column align-items-center align-items-md-start justify-content-center text-center text-md-left mb-4 mb-md-0">
        <img style={{ width: "150px", maxWidth: "100%", height: "auto" }} src="/full-logo.png" alt="Logo" />
        <p className="mt-3 mb-0">{translate('AllRightsReserved')}</p>
    </Col>

    {/* Column 2: Office Locations & Contact Information */}
    <Col xs={12} md={4} className="text-center text-md-left mb-4 mb-md-0">
        <div className="mb-3">
            <h5 className="mb-2">Office Locations:</h5>
            <p className="mb-2">
                <strong>Automated Tax Credits</strong><br />
                5342 Clark Rd Unit 1236<br />
                Sarasota, FL 34233
            </p>
            <p>
                <strong>Automated Tax Credits</strong><br />
                4021 W WALNUT ST Unit 1013<br />
                ROGERS, AR 72756-1842
            </p>
        </div>
        <div>
            <h5 className="mb-2">Contact Information:</h5>
            <p>
                Phone/ SMS: <a href="tel:+12133291786">+1 (213) 329 1786</a><br />
                Support: <a href="mailto:help@automatedtaxcredits.com">help@automatedtaxcredits.com</a>
            </p>
        </div>
    </Col>

    {/* Column 3: Secure Logo and Links */}
    <Col xs={12} md={4} className="d-flex flex-column align-items-center align-items-md-end justify-content-center text-center text-md-right">
        <SecureLogo />
        <div className="d-flex flex-row justify-content-center justify-content-md-end gap-3 mt-3">
            <a href="https://automatedtaxcredits.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">{translate('TermsAndConditions')}</a>
            <a href="https://automatedtaxcredits.com/privacy-policy/" target="_blank" rel="noopener noreferrer">{translate('PrivacyPolicy')}</a>
            <a href="https://automatedtaxcredits.com/money-back-guarantee/" target="_blank" rel="noopener noreferrer">{translate('MoneyBackGuarantee')}</a>
        </div>
    </Col>
</Row>

{/* Disclosure Section */}
<Row className='mt-4'>
    <Col xs={12} className="text-center text-md-left">
        <p>
            <b>{translate('Disclosure')}: </b>{translate('AtcPreparesTaxDocumentsAsPrivateTaxPreparationCompany')}
        </p>
    </Col>
</Row>

            </Container>
        </footer>
    );
}

export default ThanksFooter;
