import React, { useEffect, useState } from "react";
import { Row, Button, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { taxTypePageLoadedEvent, taxTypePageCompletedEvent } from '../component/FacebookPixelEvents';
import axios from 'axios';
import FullScreenSpinner from '../component/FullScreenSpinner';
import Pages from './pages';

function TaxType({ activePage, setActivePage, formData, setFormData }) {

    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const translate = (key) => {
        return t(`TaxType.${key}`);
    };

    useEffect(() => {
        if (activePage === Pages.TaxType) {
            taxTypePageLoadedEvent();
        }
    }, [activePage]);

    const next = async (e) => {
        // Skip the next PaymentPlan Step
        const xanoId = await postToXano();

        setActivePage(Pages.IssueReason);

        setFormData({
            ...formData,
            TaxType: e,
            PaymentPlan: 'NA',
            Zipcode: '-',
            State: '-',
            County: '-',
            XanoId: xanoId
        });

        taxTypePageCompletedEvent();
    };

    const postToXano = async () => {
        setLoading(true);

        const url = 'https://xyrm-sqqj-hx6t.n7c.xano.io/api:mf0ILYj9/taxquotesuserdata';
        const payload = {
            Stage: '1',
        };

        try {
            const response = await axios.post(url, payload);
            const xanoId = response.data.id;

            setLoading(false);
            return xanoId;
        }
        catch (e) {
            setLoading(false);
            console.log('Error: ', e);
        }
    };


    return (
        <div className="initial-step-container" style={{ padding: '0 5%' }}>
            <Row className="text-center mt-4">
                <Col>
                    <h2 className="heading_emp" style={{ fontSize: 'clamp(1.5rem, 4vw, 2.5rem)', fontWeight: 'bold', color: '#0f4761', marginBottom: '1.5rem' }}>
                        {translate('IsYourTaxPersonalRrBusinessRelated')}
                    </h2>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() => next('Personal')}
                        style={{
                            width: '100%',
                            maxWidth: '240px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            padding: '12px 0',
                            backgroundColor: '#FF9900',
                            color: '#000',
                            border: 'none',
                            marginBottom: '12px',
                            transition: 'background-color 0.3s ease',
                            cursor: 'pointer',
                        }}
                        onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                        onMouseOut={(e) => e.target.style.backgroundColor = '#FF9900'}
                    >
                        {translate('Personal')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() => next('Business')}
                        style={{
                            width: '100%',
                            maxWidth: '240px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            padding: '12px 0',
                            backgroundColor: '#FF9900',
                            color: '#000',
                            border: 'none',
                            marginBottom: '12px',
                            transition: 'background-color 0.3s ease',
                            cursor: 'pointer',
                        }}
                        onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                        onMouseOut={(e) => e.target.style.backgroundColor = '#FF9900'}
                    >
                        {translate('Business')}
                    </Button>
                </Col>
            </Row>

            <Row className="text-center mt-3">
                <Col>
                    <Button
                        className="normal-button"
                        type="button"
                        onClick={() => next('Both')}
                        style={{
                            width: '100%',
                            maxWidth: '240px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            padding: '12px 0',
                            backgroundColor: '#FF9900',
                            color: '#000',
                            border: 'none',
                            marginBottom: '12px',
                            transition: 'background-color 0.3s ease',
                            cursor: 'pointer',
                        }}
                        onMouseOver={(e) => e.target.style.backgroundColor = '#e68a00'}
                        onMouseOut={(e) => e.target.style.backgroundColor = '#FF9900'}
                    >
                        {translate('Both')}
                    </Button>
                </Col>
            </Row>

            {loading && (
                <Row>
                    <FullScreenSpinner />
                </Row>
            )}
        </div>



    );
}

export default TaxType;
