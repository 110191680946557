import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import LandingPage from './page/LandingPage';

function App()
{
  const RedirectWithQuery = ({ to }) =>
  {
    const location = useLocation();
    const queryParams = location.search;

    return <Navigate to={`${to}${queryParams}`} />;
  };


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="*" element={<RedirectWithQuery to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
