import React, { useState, useEffect } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "../asset/css/nunito-font.css";
import "./Landing.css";
import TaxType from './TaxType';
import IssueReason from './IssueReason';
import UnfiledReturn from './UnfiledReturn';
import IrsOwe from './IrsOwe';
import ProtectAssets from './ProtectAssets';
import ContactReason from './ContactReason';
import ContactInfo from './ContactInfo';
import Thanks299 from './Thanks299';
import Thanks9 from './Thanks9';
import Direct9 from './Direct9';
import Direct299 from './Direct299';
import Upsell299 from './Upsell299';
import Checkout9 from './Checkout9';
import Checkout299 from './Checkout299';
import PaymentSuccess from './PaymentSuccess';
import GeneralFooter from '../component/GeneralFooter';
import ThanksFooter from '../component/ThanksFooter';
import CheckoutFooter from '../component/CheckoutFooter';
import PostPurchaseFooter from '../component/PostPurchaseFooter';
import Header from '../component/Header';
import LanguageSwitcher from '../component/LanguageSwitcher';
import Pages from './pages';


function LandingPage()
{
    const location = useLocation();

    const [activeIndex, setActiveIndex] = useState(1);
    const [activePage, setActivePageRef] = useState(Pages.TaxType);  // I changed the key from 1 to 10 so it always load the 10th step. this way I don't have to fill the form again ang agin.
    const [formData, setFormDataRef] = useState({});

    const getQueryParams = (search) =>
    {
        return new URLSearchParams(search);
    };

    const queryParams = getQueryParams(location.search);
    const showComponent = !queryParams.get('full');

    const directLinkToThanks299 = queryParams.get('p') === '299';
    const directLinkTo9 = queryParams.get('p') === 'd9';
    const directLinkTo299 = queryParams.get('p') === 'd299';

    const leadSource = queryParams.get('source') || null;

    useEffect(() =>
    {
        if (directLinkTo9)
        {
            setActivePage(Pages.Direct9);
        }
    }, [activePage, directLinkTo9]);

    useEffect(() =>
    {
        if (directLinkTo299)
        {
            setActivePage(Pages.Direct299);
        }
    }, [directLinkTo299]);

    const navItems = [
        { index: 1, eventKey: Pages.TaxType, title: "TaxType" },
        // { index: 2, eventKey: Pages.PaymentPlan, title: "PaymentPlan" },
        { index: 3, eventKey: Pages.IssueReason, title: "IssueReason" },
        // { index: 4, eventKey: Pages.Zipcode, title: "Zipcode" },
        { index: 5, eventKey: Pages.UnfiledReturn, title: "UnfiledReturn" },
        { index: 6, eventKey: Pages.IrsOwe, title: "IrsOwe" },
        { index: 7, eventKey: Pages.ProtectAssets, title: "ProtectAssets" },
        { index: 8, eventKey: Pages.ContactReason, title: "ContactReason" },
        { index: 9, eventKey: Pages.ContactInfo, title: "ContactInfo" }, // Opt-in form page
        { index: 10, eventKey: Pages.Thanks9, title: "Thanks9" },  // Thanks 9 page
        { index: 11, eventKey: Pages.Direct9, title: "Direct9" },  // Direct 9 page
        { index: 12, eventKey: Pages.Checkout9, title: "Checkout9" },  // Checkout9 page
        { index: 13, eventKey: Pages.Upsell299, title: "Upsell299" },  // Upsell299 page
        { index: 14, eventKey: Pages.Thanks299, title: "Thanks299" },  // Thanks299 page
        { index: 15, eventKey: Pages.Direct299, title: "Direct299" },  // Direct 299 page
        { index: 16, eventKey: Pages.Checkout299, title: "Checkout299" },  // Checkout299 page
        { index: 17, eventKey: Pages.PaymentSuccess, title: "PaymentSuccess" },  // Success page
    ];

    const setActivePage = (e) =>
    {
        const item = navItems.find(item => item.eventKey === e);
        if (!item)
        {
            console.log('How come');
            return;
        }

        // console.log('Event: ', e);

        setActivePageRef(item.eventKey);
        setActiveIndex(item.index);

        const scrollToTop = () =>
        {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };

        scrollToTop();
    };

    const setFormData = (e) =>
    {
        if (!e.LeadSource && leadSource)
        {
            e.LeadSource = leadSource;
        }

        setFormDataRef(e);
    };

    return (
        <Container className="text-center">
            {showComponent && <Header />}

            <div className="form-body position-relative">
                <Tab.Container
                    defaultActiveKey={Pages.TaxType}
                    activeKey={activePage}
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    fill
                >
                    <LanguageSwitcher />

                    <Nav variant="tabs" className='d-none d-md-flex'>
                        {navItems.map((item, idx) => (
                            <Nav.Item key={idx}>
                                <Nav.Link
                                    eventKey={item.eventKey}
                                    className={activeIndex > item.index ? "passed" : ""}
                                    onClick={(e) => e.preventDefault()}
                                >
                                    {item.title}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="TaxType" title="TaxType">
                            <TaxType
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="PaymentPlan" title="PaymentPlan">
                            <PaymentPlan
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane> */}
                        <Tab.Pane eventKey="IssueReason" title="IssueReason">
                            <IssueReason
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="IssueReason" title="IssueReason">
                            <Zipcode
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane> */}
                        <Tab.Pane eventKey="UnfiledReturn" title="UnfiledReturn">
                            <UnfiledReturn
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="IrsOwe" title="IrsOwe">
                            <IrsOwe
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="ProtectAssets" title="ProtectAssets">
                            <ProtectAssets
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="ContactReason" title="ContactReason">
                            <ContactReason
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="ContactInfo" title="ContactInfo">
                            <ContactInfo
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                                directLinkToThanks299={directLinkToThanks299}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Thanks9" title="Thanks9">
                            <Thanks9
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Direct9" title="Direct9">
                            <Direct9
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                                directLinkTo9={directLinkTo9}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Checkout9" title="Checkout9">
                            <Checkout9
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Upsell299" title="Upsell299">
                            <Upsell299
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Thanks299" title="Thanks299">
                            <Thanks299
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Direct299" title="Direct299">
                            <Direct299
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Checkout299" title="Checkout299">
                            <Checkout299
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="PaymentSuccess" title="PaymentSuccess">
                            <PaymentSuccess
                                activePage={activePage}
                                setActivePage={setActivePage}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>

            {/* And here are the separate footers */}
            {/* All the steps except 10, 11, 12, 13 will have General Footer */}
            {showComponent && ![10, 11, 12, 13, 16, 17].includes(activePage) && <GeneralFooter />}
            {/* Step 10 have Thanks Footer as this is Thanks299 page  */}
            {showComponent && activePage === 10 && <ThanksFooter />}
            {/* Step 11 checkout page and Footer  */}
            {showComponent && activePage === 11 && <CheckoutFooter />}
            {/* Step 12 post purchase page and Footer  */}
            {showComponent && activePage === 12 && <PostPurchaseFooter />}
            {/* Step 13 Thanks9 page and Footer  */}
            {showComponent && activePage === 13 && <ThanksFooter />}
        </Container>
    );
}

export default LandingPage;
/**
 *
 * git checkout <branch-name> // to checkout to an existing branch
 * git checkout -b <branch-name> // to create a new branch and checkout to it
 * That button is to publish the branch, so everyone can see it. If you don't publish this, it will be on your machine only.
 * I'll add the direct links for both pages and push it to you branch and then we will create a PR to merge it with main
 *
 *
 *
 * Pushing the code has 3 steps.
 * Stage the changes
 * Commit the changes
 * And then push them, your push maybe rejected if there is anything to pull on remote. you will see it here
 * Let's do the first thing
 * Review and stage the changes
 * That + sign is to stage that file
 * First step done, the changes are staged, now commit
 * Second step completed, changes commited
 * You'll see you have nothing to pull but 1 thing to push.
 * Once done you can click that button to push, or type git push in the terminal.
 * Alright?
 */
