import React from "react";
import { Row, Col, Image } from "react-bootstrap";


function SocialProof()
{
    const images = [
        '/irs-proofs/proof-1.png', '/irs-proofs/proof-2.png', '/irs-proofs/proof-3.png', '/irs-proofs/proof-4.png', '/irs-proofs/proof-5.png', '/irs-proofs/proof-6.png'
    ];

    return (
        <Row>
            {images.map((image, index) => (
                <Col xs={12} sm={12} md={6} key={index} className="mb-3">
                    <Image style={{ maxWidth: '100%' }} src={image} thumbnail fluid />
                </Col>
            ))}
        </Row>
    );
}

export default SocialProof;
